import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Registration from './pages/Registration';
// import Login from './pages/Login';
import './App.css';
import Main from './pages/Main';
import Check from './pages/Check';

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<main>
					<Routes>
						<Route path='/' element={<Main />} />
						<Route
							path='/registration'
							element={<Registration />}
						/>
						<Route path='/check' element={<Check />} />
						<Route path='*' element={<Navigate to='/' replace />} />
					</Routes>
				</main>
			</BrowserRouter>
		</div>
	);
}

export default App;
