import React, { useEffect, useState } from 'react';
import './Main.css';

import { Link } from 'react-router-dom';
import logo1 from '../assets/logo1.png';
import logo2 from '../assets/logo2.png';
import logo3 from '../assets/sportLogo.png';
import mobileLogo1 from '../assets/mobilelogo1.png';
import mobileLogo2 from '../assets/mobilelogo2.png';
import mobileLogo from '../assets/mobilelogo.png';
import tashabbusLogo from '../assets/5T.png';
import Layout from '../Layout/Layout';
import axios from 'axios';
const Main = () => {
	// useEffect(() => {
	// 	fetch(
	// 		'https://adm-integration.sport.uz/api/v1/tashabbus5/helper/GetAllOblast?lang=3',
	// 		{
	// 			headers: {
	// 				login: '5tashabbus',
	// 				pswd: 'MyK0tZt?GHZJx;abXov',
	// 			},
	// 		}
	// 	);
	// }, []);

	return (
		<Layout>
			<section className='main-page '>
				<div className='content'>
					<div className='main-content'>
						<div className='text'>
							<p>
								<span>
									Besh tashabbus olimpiadasi va
									paralimpiadasida{' '}
								</span>
								faol ishtirok eting
							</p>
						</div>
						<div className='logos'>
							<img width={180} src={logo1} alt='' />
							<img width={184} src={logo2} alt='' />
							<img width={184} src={logo3} alt='' />
						</div>
					</div>
				</div>
				<div className='mobile-logos  flex-column align-items-center'>
					<div className=''>
						<img
							id='img-1'
							width={250}
							src={tashabbusLogo}
							alt=''
						/>
					</div>
					<div className='mt-4'>
						<img
							id='img-3'
							className='ms-3'
							width={180}
							src={logo3}
							alt=''
						/>
						<img
							id='img-2'
							className=''
							width={120}
							src={logo1}
							alt=''
						/>
					</div>
					{/* <img src={mobileLogo} alt='' /> */}
					{/* <img src={mobileLogo2} alt='' /> */}
				</div>
				<div className='bg-image'>
					<div className='buttons'>
						<Link to='registration'>
							<button className='button green'>
								Online ro’yxatdan o’tish
							</button>
						</Link>
						<Link to='/check'>
							<button className='button blue'>
								Ma'lumotlarni ko'rish
							</button>
						</Link>
					</div>
				</div>
				<a
					href='http://www.uz/uz/res/visitor/index?id=46379'
					className='calculation'
					target='_top'>
					<img
						height='31'
						src='http://cnt0.www.uz/counter/collect?id=46379&pg=http%3A//uzinfocom.uz&&col=0063AF&amp;t=ffffff&amp;p=DD7900'
						width='88'
						border='0'
						alt='Топ рейтинг'
					/>
				</a>
			</section>
		</Layout>
	);
};

export default Main;
