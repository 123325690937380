import axios from 'axios';

export const BASE_URL = 'https://adm.beshtashabbus.uz/api/';

let axiosInstance = axios.create({
	baseURL: BASE_URL,
	timeout: 30000, //1000ms
});
axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		return error;
	}
);

export default axiosInstance;

// export const
