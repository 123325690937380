import React, { useEffect, useRef, useState } from 'react';
import Modal from './Modal';

import './BaseModal.css';
import BIRTH_SERIA from '../api/birth_certificate_seria';
import { useNavigate } from 'react-router-dom';

const BaseModal = ({
	isModal,
	error,
	// closeModal,
	sendDocumentHandler,
	setDocument,
	document,
	message,
	typeRegistration,
	setTypeRegistration,
	setErrorMessage,
	setError,
	setLoadingModal,
	loadingModal,
}) => {
	const navigate = useNavigate();
	const registerTypeHandler = (e) => {
		setTypeRegistration(e.target.value);
	};

	const image = useRef();

	const handlePhotoChange = (event) => {
		const file = event.target.files[0];

		// Check if file exists
		if (file) {
			// Check if file type is valid
			if (file.type === 'image/jpeg' || file.type === 'image/png') {
				const reader = new FileReader();
				reader.onload = (e) => {
					image.current.src = e.target.result;

					setDocument((prev) => ({
						...prev,
						main_photo: e.target.result,
					}));
				};
				reader.readAsDataURL(file);
			} else {
				// If file type is not valid, alert user
				setError(true);
				setErrorMessage('Jpg yoki Png formatida rasm yuklang');
				setTimeout(() => {
					setError(false);
					setErrorMessage('');
				}, 3000);
			}
		}
	};
	const closeModal = () => {
		navigate('/');
	};

	return (
		<Modal
			// isMain={true}
			isModal={isModal}
			error={error}
			message={message}
			closeModal={closeModal}>
			<form onSubmit={sendDocumentHandler}>
				<div className='form-item mt-2'>
					<label htmlFor='document_type'>
						Hujjat turini kiriting
					</label>
					<select
						onChange={registerTypeHandler}
						name='passport'
						id='document_type'>
						<option value='passport'>
							Pasport yoki id karta orqali
						</option>
						<option value='birth_certificate'>
							Tug'ilganlik haqida guvohnoma orqali
						</option>
					</select>
				</div>
				{typeRegistration === 'passport' ? (
					<>
						<div className='form-item mt-2'>
							<label htmlFor='seria'>
								Pasport seriyasini kiriting
							</label>
							<input
								name='seria'
								type='text'
								id='seria'
								value={document.seria}
								placeholder='AB'
								maxLength='2'
								onChange={(e) => {
									if (document.seria.length < 3) {
										setDocument({
											...document,
											seria: e.target.value.toUpperCase(),
										});
									}
								}}
							/>
						</div>
						<div className='form-item mt-2'>
							<label htmlFor='pasport-number'>
								Pasport raqamini kiriting
							</label>
							<input
								type='text'
								id='pasport-number'
								name='pasport-number'
								value={document.number}
								placeholder='_______'
								onChange={(e) => {
									if (document.number.length <= 7) {
										setDocument({
											...document,
											number: e.target.value,
										});
									}
								}}
								maxLength='7'
							/>
						</div>

						<div className='form-item mt-2'>
							<label htmlFor=''>
								Tug'ilgan sanangizni kiriting
							</label>
							<input
								name='birth-year'
								value={document.birthdate}
								onChange={(evt) => {
									setDocument({
										...document,
										birthdate: evt.target.value,
									});
								}}
								type='date'
							/>
						</div>
					</>
				) : typeRegistration === 'birth_certificate' ? (
					<>
						<div className='form-item mt-2'>
							<label htmlFor='birth-number'>
								Guvohnoma seriyasi
							</label>
							<select
								required
								onChange={(e) => {
									setDocument({
										...document,
										birth_seria:
											e.target.value.toUpperCase(),
									});
								}}
								id='birth-number'>
								<option disabled selected value={''}>
									Seriani tanlang
								</option>
								{BIRTH_SERIA.map((seria, i) => (
									<option key={i} value={seria}>
										{seria}
									</option>
								))}
							</select>
						</div>
						<div className='form-item mt-2'>
							<label htmlFor='birth-seria'>
								Guvohnoma raqami
							</label>
							<input
								name='seria'
								type='text'
								id='birth-seria'
								value={document.birth_number}
								placeholder='0000000'
								maxLength={8}
								onChange={(e) => {
									if (document.birth_number.length < 9) {
										setDocument({
											...document,
											birth_number: e.target.value,
										});
									}
								}}
							/>
						</div>

						<div className='form-item mt-2'>
							<label htmlFor=''>
								Tug'ilgan sanangizni kiriting
							</label>
							<input
								name='birth-year'
								value={document.birthdate}
								onChange={(evt) => {
									setDocument({
										...document,
										birthdate: evt.target.value,
									});
								}}
								type='date'
							/>
						</div>

						<div className='form-item mt-2'>
							<label htmlFor='avatar-image'>
								<p> Rasmingizni yuklang</p>

								<div className='photo-field'>
									<img
										ref={image}
										id='photo-preview'
										className={`photo-result`}
										alt=''
									/>
								</div>
								<input
									className='d-none'
									type='file'
									id='avatar-image'
									name='pasport-number'
									value={document.photo}
									placeholder='_______'
									onChange={handlePhotoChange}
								/>
							</label>
						</div>
					</>
				) : (
					<>
						<div className='form-item mt-2'>
							<label htmlFor='seria'>Id karta</label>
							<input
								name='seria'
								type='text'
								id='seria'
								value={document.seria}
								placeholder='xxxxxxxxxxxxxx'
								maxLength='2'
								onChange={(e) => {
									if (document.seria.length < 3) {
										setDocument({
											...document,
											seria: e.target.value.toUpperCase(),
										});
									}
								}}
							/>
						</div>
					</>
				)}

				{loadingModal ? (
					<p id='button' className='w-100 mx-auto'>
						Iltimos kuting ...
					</p>
				) : (
					<button id='button' type='submit' className='w-100'>
						Yuborish
					</button>
				)}
			</form>
		</Modal>
	);
};

export default BaseModal;
