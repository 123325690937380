import React, { useState } from 'react';

import logo from '../assets/logo1.svg';
import hamburger from '../assets/hamburger-menu.svg';
import { Link } from 'react-router-dom';
const Header = () => {
	const [isMobile, setIsMobile] = useState(false);

	return (
		<header className='header position-fixed  container'>
			<nav className='nav d-flex align-items-center gap-3'>
				<div>
					<a href='https://yoshlarportali.uz/oz'>
						<img
							src={logo}
							width={80}
							height={85}
							alt='yoshlar portali'
						/>
					</a>
				</div>
				<ul className='nav-list'>
					<li>
						<Link to={'/'}>Bosh sahifa</Link>
					</li>
					<li>
						<a href='https://yoshlarportali.uz/oz/opportunities'>
							Imkoniyatlar
						</a>
					</li>
					<li>
						<a href='https://yoshlarportali.uz/oz/projects'>
							Loyihalar
						</a>
					</li>
					<li>
						<a href='https://yoshlarportali.uz/oz/ideas'>
							G'oyalar
						</a>
					</li>
					<li>
						<a href='https://yoshlarportali.uz/oz/news'>
							Yangiliklar
						</a>
					</li>
					<li>
						<a href='https://yoshlarportali.uz/oz/about-us'>
							Biz haqimizda
						</a>
					</li>
				</ul>

				<div className='mobile-menu'>
					<img
						src={hamburger}
						alt=''
						onClick={() => setIsMobile((prev) => !prev)}
					/>

					<div
						className={`mobile-tab  container  ${
							isMobile ? 'mobile-tab-active' : ''
						}`}>
						<div className='mobile-header d-flex justify-content-between align-items-center'>
							<div>
								<a href='https://yoshlarportali.uz/oz'>
									<img
										src={logo}
										width={80}
										height={85}
										alt='yoshlar portali'
									/>
								</a>
							</div>
							<div onClick={() => setIsMobile(false)}>
								<img
									src='https://yoshlarportali.uz/static/media/close.f0a8177a.svg'
									alt=''
								/>
							</div>
						</div>
						<ul>
							<li className='text-center mb-2'>
								<Link to='/'>Bosh sahifa</Link>
							</li>
							<li className='text-center'>
								<a href='https://yoshlarportali.uz/oz/opportunities'>
									Imkoniyatlar
								</a>
							</li>
							<li className='text-center mt-3'>
								<a href='https://yoshlarportali.uz/oz/projects'>
									Loyihalar
								</a>
							</li>
							<li className='text-center mt-3'>
								<a href='https://yoshlarportali.uz/oz/ideas'>
									G'oyalar
								</a>
							</li>
							<li className='text-center mt-3'>
								<a href='https://yoshlarportali.uz/oz/news'>
									Yangiliklar
								</a>
							</li>
							<li className='text-center mt-3'>
								<a href='https://yoshlarportali.uz/oz/about-us'>
									Biz haqimizda
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>
	);
};

export default Header;
