const BIRTH_SERIA = [
	'I-TN',
	'I-TV',
	'I-HR',
	'I-FR',
	'I-SU',
	'I-SR',
	'I-SM',
	'I-NA',
	'I-NV',
	'I-QD',
	'I-GZ',
	'I-BH',
	'I-AN',
	'I-QQ',
	'III-TN',
	'II-TN',
	'II-TV',
	'II-HR',
	'II-FR',
	'II-SU',
	'II-SR',
	'II-SM',
	'II-NA',
	'II-NV',
	'II-QD',
	'II-GZ',
	'II-BH',
	'II-AN',
	'II-QQ',
	'II-TS',
	'II-KS',
	'III-SU',
	'TA',
	'III-TV',
	'III-NA',
	'III-SR',
	'III-AN',
	'III-BH',
	'III-NV',
	'III-HR',
	'III-QQ',
	'III-SM',
	'III-QD',
	'III-FR',
	'T',
	'III-GZ',
];
export default BIRTH_SERIA;
