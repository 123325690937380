import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../api/axiosInstance';
import Modal from '../components/Modal';
import Layout from '../Layout/Layout';
import BIRTH_SERIA from '../api/birth_certificate_seria';
const Check = () => {
	const [showModal, setShowModal] = useState(true);
	const [error, setError] = useState(false);
	const [document, setDocument] = useState({
		seria: '',
		number: '',
		birth_number: '',
	});
	const [typeRegistration, setTypeRegistration] = useState('passport');

	const navigate = useNavigate();

	const [data, setData] = useState([]);

	const closeModal = () => {
		navigate('/');
	};

	const registerTypeHandler = (e) => {
		setTypeRegistration(e.target.value);
	};

	const checkDocumentHandler = (e) => {
		e.preventDefault();

		const seria_number =
			typeRegistration === 'passport'
				? document.seria + document.number
				: document.birth_seria + ' ' + document.birth_number;

		axios(BASE_URL + 'check-participant-status/' + seria_number).then(
			(res) => {
				if (res.status === 200 && res.data.length > 0) {
					setShowModal(false);
					setData(res.data);
				} else if (res.data.status === 'not found') {
					setError(true);
				}
			}
		);
	};
	return (
		<Layout>
			<section>
				<Modal
					isModal={showModal}
					closeModal={closeModal}
					error={error}
					isCheck={true}>
					<form onSubmit={checkDocumentHandler}>
						<div className='form-item mt-2'>
							<label htmlFor='document_type'>
								Hujjat turini kiriting
							</label>
							<select
								onChange={registerTypeHandler}
								name='passport'
								id='document_type'>
								<option value='passport'>
									Pasport yoki id karta orqali
								</option>
								<option value='birth_certificate'>
									Tug'ilganlik haqida guvohnoma orqali
								</option>
							</select>
						</div>
						{typeRegistration === 'passport' ? (
							<>
								<div className='form-item mt-2'>
									<label htmlFor='seria'>
										Pasport seriyasini kiriting
									</label>
									<input
										name='seria'
										type='text'
										id='seria'
										value={document.seria}
										placeholder='AB'
										maxLength='2'
										onChange={(e) => {
											if (document.seria.length < 3) {
												setDocument({
													...document,
													seria: e.target.value.toUpperCase(),
												});
											}
										}}
									/>
								</div>
								<div className='form-item mt-2'>
									<label htmlFor='pasport-number'>
										Pasport raqamini kiriting
									</label>
									<input
										type='text'
										id='pasport-number'
										name='pasport-number'
										value={document.number}
										placeholder='_______'
										onChange={(e) => {
											if (document.number.length <= 7) {
												setDocument({
													...document,
													number: e.target.value,
												});
											}
										}}
										maxLength='7'
									/>
								</div>
							</>
						) : (
							<>
								<div className='form-item mt-2'>
									<label htmlFor='birth-number'>
										Guvohnoma seriyasi
									</label>
									<select
										required
										onChange={(e) => {
											setDocument({
												...document,
												birth_seria:
													e.target.value.toUpperCase(),
											});
										}}
										id='birth-number'>
										<option disabled selected value={''}>
											Seriani tanlang
										</option>
										{BIRTH_SERIA.map((seria, i) => (
											<option key={i} value={seria}>
												{seria}
											</option>
										))}
									</select>
								</div>
								<div className='form-item mt-2'>
									<label htmlFor='birth-seria'>
										Guvohnoma raqami
									</label>
									<input
										name='seria'
										type='text'
										id='birth-seria'
										value={document.birth_number}
										placeholder='0000000'
										maxLength={8}
										onChange={(e) => {
											if (
												document.birth_number.length < 9
											) {
												setDocument({
													...document,
													birth_number:
														e.target.value,
												});
											}
										}}
									/>
								</div>

								<div className='form-item mt-2'>
									<label htmlFor=''>
										Tug'ilgan sanangizni kiriting
									</label>
									<input
										name='birth-year'
										value={document.birthdate}
										onChange={(evt) => {
											setDocument({
												...document,
												birthdate: evt.target.value,
											});
										}}
										type='date'
									/>
								</div>
							</>
						)}
						<button id='button' type='submit' className='w-100'>
							Tekshirish
						</button>
					</form>
				</Modal>
				{data.length > 0 && (
					<div
						style={{
							marginTop: '50px',
						}}
						className='container table-responsive py-5 '>
						<table className='table table-bordered table-hover'>
							<thead className='thead-dark'>
								<tr>
									<th scope='col'>#</th>
									<th scope='col'>Ishtirokchi</th>
									<th scope='col'>Yo'nalish</th>
									<th scope='col'>Manzil</th>
									<th scope='col'>
										Ro'yxatdan o'tilgan sana
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, i) => (
									<tr>
										<th scope='row'>{i + 1}</th>
										<td>{item.name}</td>
										<td>{item.category.name}</td>
										<td>
											{item.address + ', '}{' '}
											{item.district.name + ', '}
											{item.region.name + ', '}{' '}
											{item.province.name}
										</td>
										<td>{item.created_at}</td>
									</tr>
									// <div key={i} className='row order__table '>

									// 	<p className='col-1'>{i + 1}</p>
									// 	<p className='col-3'>{item.name}</p>
									// 	<p className='col-3'>
									// 		{item.category.name}
									// 	</p>
									// 	<p className='col-3'>
									// 		{item.address + ', '}{' '}
									// 		{item.district.name + ', '}
									// 		{item.region.name + ', '}{' '}
									// 		{item.province.name}
									// 	</p>
									// 	<p className='col-2'>{item.created_at}</p>
									// </div>
								))}
							</tbody>
						</table>
					</div>
				)}
				<a
					href='http://www.uz/uz/res/visitor/index?id=46379'
					className='calculation'
					target='_top'>
					<img
						height='31'
						src='http://cnt0.www.uz/counter/collect?id=46379&pg=http%3A//uzinfocom.uz&&col=0063AF&amp;t=ffffff&amp;p=DD7900'
						width='88'
						border='0'
						alt='Топ рейтинг'
					/>
				</a>
			</section>
		</Layout>
	);
};

export default Check;
