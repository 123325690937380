import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../api/axiosInstance';
import BaseModal from '../components/BaseModal';

import Modal from '../components/Modal';
import Layout from '../Layout/Layout';
import generateOld from '../utils/generateOld';

import infoIcon from '../assets/information.png';

const regions = require('../regions/regions.json');
const quarters = require('../regions/quarters.json');
const districts = require('../regions/districts.json');

const Registration = () => {
	const [document, setDocument] = useState({
		seria: '',
		number: '',
		birthdate: '',
		pinfl: '',
		birth_number: '',
		main_photo: null,
	});
	const [typeRegistration, setTypeRegistration] = useState('passport');
	const [documentData, setDocumentData] = useState({});

	const [districtsList, setDistrictsList] = useState([]);
	const [quartersList, setQuartersList] = useState([]);
	const [data, setData] = useState({
		phone: '+998',
	});
	const [categoriesList, setCategoriesList] = useState([]);
	const [innerCategoriesList, setInnerCategoriesList] = useState([]);
	const [organizations, setOrganizations] = useState([]);

	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [error2, setError2] = useState(false);
	const [message, setMessage] = useState('');
	const [isModal, setIsModal] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(true);

	// const [isTeam, setIsTeam] = useState(false);
	// const [isSolo, setIsSolo] = useState(false);
	// const [category2, setCategory2] = useState([]);
	const [category, setCategory] = useState('');

	const [checked, setChecked] = useState(false);

	const [file, setFile] = useState('');

	const organizationSelect = useRef();

	const navigate = useNavigate();

	const [isSuccess, setIsSuccess] = useState(false);

	const [loadingModal, setLoadingModal] = useState(false);
	const [loadingType, setLoadingType] = useState(false);
	const [paralimpycType, setParalimpycType] = useState('');

	const [smallCategoryList, setSmallCategoryList] = useState([]);

	const [age, setAge] = useState(null);
	const getOrganizations = () => {
		setLoading(true);
		axios.get(BASE_URL + 'organizations').then((res) => {
			setOrganizations(res.data);
			setLoading(false);
		});
	};
	useEffect(() => {
		if (documentData.birth_date && documentData.gender) {
			axios
				.get(
					BASE_URL +
						`categories?age=${
							generateOld(documentData.birth_date)
							// age
						}&gender=${documentData.gender === 'male' ? 'M' : 'F'}`
				)
				.then((res) => {
					setCategoriesList(res.data);
				});
		}
		getOrganizations();
	}, [documentData]);

	//  Address functions
	function handleProvinceSelectChange(e) {
		setLoadingType(true);
		axios.get(BASE_URL + 'regions/' + e.target.value).then((res) => {
			setDistrictsList(res.data);
			setLoadingType(false);
		});

		setData({
			...data,
			province: e.target.value,
		});

		// const arr = [];
		// districts.forEach((district) => {
		// 	if (district.region_id == e.target.value) {
		// 		arr.push(district);
		// 	}
		// });
		// setData({
		// 	...data,
		// 	province: e.target.value,
		// });
		// setDistrictsList(arr);
	}

	function handleRegionSelectChange(e) {
		setLoadingType(true);
		axios.get(BASE_URL + 'districts/' + e.target.value).then((res) => {
			setQuartersList(res.data);
			setLoadingType(false);
		});
		setData({
			...data,
			region: e.target.value,
		});
	}

	// document function
	const sendDocumentHandler = async (e) => {
		e.preventDefault();

		setLoadingModal(true);

		const currentYear = new Date().getFullYear();
		const birthdate = new Date(document.birthdate);
		const birthYear = birthdate.getFullYear();
		const birthMonth = birthdate.getMonth();

		const is31 = currentYear - birthYear === 31 && birthMonth < 3;

		if (currentYear - birthYear === 31 && birthMonth > 3) {
			setAge(30);
		}

		setAge(currentYear - birthYear);

		if (currentYear - birthYear > 30 && is31) {
			setError(true);
			setErrorMessage(
				"Tanlovda 30 yoshgacha bo'lgan yoshlar qatnasha oladi."
			);
			setTimeout(() => {
				setError(false);
				setErrorMessage('');
			}, 3000);
			setLoadingModal(false);
			return;
		}
		if (currentYear - birthYear > 31) {
			setError(true);
			setErrorMessage(
				"Tanlovda 30 yoshgacha bo'lgan yoshlar qatnasha oladi."
			);
			setTimeout(() => {
				setError(false);
				setErrorMessage('');
			}, 3000);
			setLoadingModal(false);
			return;
		}
		// if (currentYear - age > 30) {
		// 	setError(true);
		// 	setErrorMessage(
		// 		"Tanlovda 30 yoshgacha bo'lgan yoshlar qatnasha oladi."
		// 	);
		// 	setTimeout(() => {
		// 		setError(false);
		// 		setErrorMessage('');
		// 	}, 3000);
		// 	return;
		// }
		try {
			if (typeRegistration === 'passport') {
				// const res = await axios.post(
				// 	'https://yoshlarportali.uz:17592/uz/api/users/verify-with-gcp-not-auth',
				// 	{
				// 		document: document.seria + document.number,
				// 		birthdate: document.birthdate,
				// 	}
				// );
				const formdata = new FormData();
				formdata.append('document', document.seria + document.number);
				formdata.append('birthdate', document.birthdate);
				const res = await axios.post(
					BASE_URL + 'passport-seria/',
					formdata
				);

				const data = JSON.parse(res.data.data);

				setDocument({
					...document,
					pinfl: data.user_data.pinpp,
				});
				setDocumentData(data.user_data);
				setIsModal(false);
			} else if (typeRegistration === 'birth_certificate') {
				if (!document.birth_number || !document.birth_seria) {
					setError(true);
					setErrorMessage("Iltimos ma'lumotlaringizni to'ldiring");
					setTimeout(() => {
						setError(false);
						setErrorMessage('');
					}, 3000);
					return;
				}

				if (!document.main_photo) {
					setError(true);
					setErrorMessage('Iltimos suratingizni yuklang');
					setTimeout(() => {
						setError(false);
						setErrorMessage('');
					}, 3000);
					return;
				}

				if (!document.birthdate) {
					setError(true);
					setErrorMessage("Iltimos tug'ilgan sanangizni kiriting");
					setTimeout(() => {
						setError(false);
						setErrorMessage('');
					}, 3000);
					return;
				}

				const res = await axios.post(
					'https://api.yoshlardaftari.uz/api/v2/agentlik-resources/get-fhdyo-birth-person',
					{
						cert_series: document.birth_seria,
						cert_number: document.birth_number,
						birthdate: document.birthdate,
					}
				);
				if (res.data.result.length > 0) {
					let person = {};

					if (res.data.result.length > 1) {
						const birthdate = new Date(document.birthdate);
						const { year, month, day } = {
							year: birthdate.getFullYear(),
							month: birthdate.getMonth() + 1,
							day: birthdate.getDate(),
						};

						person = res.data.result.find((element) => {
							const [day2, month2, year2] =
								element.birth_date.split('.');
							const birthdate2 = new Date(
								year2,
								month2 - 1,
								day2
							);
							const year2New = birthdate2.getFullYear();
							const month2New = birthdate2.getMonth() + 1;
							const day2New = birthdate2.getDate();

							return (
								year === year2New &&
								month === month2New &&
								day === day2New
							);
						});
					}

					const data = person.name ? person : res.data.result[0];
					setDocument({
						...document,
						pinfl: data.pnfl,
						// birthdate:
					});

					const dateString = data.birth_date;
					const parts = dateString.split('.');
					const date = new Date(parts[2], parts[1] - 1, parts[0]);
					setDocumentData({
						...data,
						first_name: data.name,
						last_name: data.surname,
						patronymic: data.patronym,
						gender: data.gender_code === 1 ? 'male' : 'female',
						birth_date: date,
					});

					setIsModal(false);
				} else {
					setError(true);
					setErrorMessage(
						"Ma'lumotlar topilmadi qaytadan urinib ko'ring"
					);
					setTimeout(() => {
						setError(false);
						setErrorMessage('');
					}, 3000);
					return;
				}
			}
		} catch (error) {
			if (error) {
				setError(true);

				setTimeout(() => {
					setError(false);
				}, 3000);
			}
		} finally {
			setLoadingModal(false);
		}
	};

	// const teamHandler = (e) => {
	// 	e.target.value === 'jamoaviy' ? setIsTeam(true) : setIsTeam(false);

	// 	setData({
	// 		...data,
	// 		team: e.target.value === 'jamoaviy' ? true : false,
	// 	});
	// };

	const organizationHandler = (e) => {
		setLoadingType(true);
		axios
			.get(
				BASE_URL +
					`categories?organization=${
						e.target.value
					}&age=${generateOld(documentData.birth_date)}&gender=${
						documentData.gender === 'male' ? 'M' : 'F'
					}`
			)
			.then((res) => {
				setCategoriesList(res.data);
				if (e.target.value == 2) {
					setParalimpycType(e.target.value);
				} else {
					setParalimpycType(null);
				}
				setLoadingType(false);
			});
	};

	const mainCategoryHandler = async (e) => {
		const target = JSON.parse(e.target.value);
		setInnerCategoriesList([]);

		setCategory({});
		if (target.name === 'Sport musobaqalari') {
			const checkSport = await axios.get(
				'https://adm.beshtashabbus.uz/api/check-sport-status/' +
					document.pinfl
				// 51904066400010
			);

			const data = JSON.parse(checkSport.data.data);

			if (data.ParticipationIsAllowed === 'Now') {
				setShowModal(true);
				setError2(true);
				setMessage(
					'Sportda razryadga ega ekanligingiz sababli  sport yo’nalishida qatnasha olmaysiz'
				);

				setInnerCategoriesList([]);
				setCategory();
				return;
			}
			const param =
				typeRegistration === 'birth_certificate'
					? document.birth_seria + document.birth_number
					: document.seria + document.number;
			const res = await axios.get(
				BASE_URL + 'check-participant-sport-status/' + param
			);

			if (res.data.available > 0) {
				setShowModal(true);
				setError2(true);
				setMessage("Siz bu yo'nalishda ro'yxatdan o'tgansiz");
				setInnerCategoriesList([]);
				setCategory();
				return;
			} else {
				// axios
				// 	.get(BASE_URL + 'age-categories/' + target.id)
				// 	.then((res) => {
				// 		setAges(res.data[0].age_categories);
				// 	});

				// setIsTeam(target.team);
				// setIsSolo(target.solo);

				axios
					.get(
						BASE_URL +
							`categories?parent=${target.id}&age=${
								generateOld(documentData.birth_date)
								// age
							}&gender=${
								documentData.gender === 'male' ? 'M' : 'F'
							}`
					)
					.then((res) => {
						if (res.data.length > 0) {
							setInnerCategoriesList([{}, ...res.data]);
						} else {
							setInnerCategoriesList([]);
							setCategory(target.id);
						}
						// setCategory2(res.data.length > 0 ? res.data : []);
					});
			}
		} else {
			// setIsTeam(target.team);
			// setIsSolo(target.solo);

			axios
				.get(
					BASE_URL +
						`categories?parent=${target.id}&age=${
							// age
							generateOld(documentData.birth_date)
						}& gender=${documentData.gender === 'male' ? 'M' : 'F'}`
				)
				.then((res) => {
					if (res.data.length > 0) {
						setInnerCategoriesList(res.data);
					} else {
						// setInnerCategoriesList([]);
						setCategory(target.id);
						setFile(target.policy_file);
					}

					// setCategory2(res.data.length > 0 ? res.data : []);
				});
		}
	};

	const innerCategoryHandler = (e) => {
		const target = JSON.parse(e.target.value);

		// setInnerCategoriesList([]);
		// axios.get(BASE_URL + 'age-categories/' + target.id).then((res) => {
		// 	setAges(res.data[0].age_categories);
		// });

		// setIsTeam(target.team);
		// setIsSolo(target.solo);

		axios
			.get(
				BASE_URL +
					`categories?parent=${target.id}&age=${
						// age
						generateOld(documentData.birth_date)
					}&gender=${documentData.gender === 'male' ? 'M' : 'F'}`
			)
			.then((res) => {
				if (res.data.length > 0) {
					setSmallCategoryList([{}, ...res.data]);
					// setInnerCategoriesList(res.data);
				} else {
					// setInnerCategoriesList([target]);
					setCategory(target.id);
					setFile(target.policy_file);
					setSmallCategoryList([]);
				}

				// setCategory2(res.data.length > 0 ? res.data : []);
			});
	};
	const smallCategoryHandler = (e) => {
		const target = JSON.parse(e.target.value);

		// setIsTeam(target.team);
		// setIsSolo(target.solo);

		axios
			.get(
				BASE_URL +
					`categories?parent=${target.id}&age=${
						// age
						generateOld(documentData.birth_date)
					}&gender=${documentData.gender === 'male' ? 'M' : 'F'}`
			)
			.then((res) => {
				if (res.data.length > 0) {
					setSmallCategoryList([{}, ...res.data]);
					// setInnerCategoriesList(res.data);
				} else {
					setSmallCategoryList([target]);
					setCategory(target.id);
					setFile(target.policy_file);
				}
				// setCategory2(res.data.length > 0 ? res.data : []);
			});
	};
	// const teamMembersHandler = (e) => {
	// 	setData({
	// 		...data,
	// 		team_members: e.target.value,
	// 	});
	// };

	// submit handler
	const submitHandler = (e) => {
		setShowModal(true);

		e.preventDefault();

		if (!checked) {
			setError2(true);
			setMessage('Nizom bilan tanishib chiqing.');
			// setTimeout(() => {
			// 	setError2(false);
			// 	setMessage('');
			// }, 3000);
			return;
		}

		const phoneRegex =
			/^\+998\s?\d{2}\s?\d{3}\s?\d{2}\s?\d{2}$|^\+998\d{9}$/;

		const isValidPhone = (phone) => {
			return phoneRegex.test(phone);
		};

		if (
			data.address === undefined ||
			data.district === undefined ||
			data.province === undefined ||
			data.region === undefined ||
			data.region === '' ||
			data.district === '' ||
			data.province === '' ||
			data.address === ''
		) {
			setShowModal(true);
			setError2(true);
			setMessage("Iltimos manzilingizni to'ldiring");
			return;
		}
		if (!isValidPhone(data.phone)) {
			setError2(true);
			setMessage("Iltimos telefon raqamingizni to'g'ri formatda yuklang");
			return;
		}

		if (!category) {
			setError2(true);
			setMessage("Iltimos yo'nalishni tanlang ");
			return;
		}
		if (paralimpycType == 2) {
			setError2(true);
			setMessage('Iltimos nogironlik turini tanlang');
			return;
		}

		const date = new Date(documentData?.birth_date);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const formattedDate = `${year}-${month}-${day}`;

		const data2 = {
			category: category,
			// name:
			// 	documentData.first_name +
			// 	' ' +
			// 	documentData.last_name +
			// 	' ' +
			// 	documentData.patronymic,
			// seria_and_number:
			// 	typeRegistration === 'birth_certificate'
			// 		? documentData.cert_series + ' ' + documentData.cert_number
			// 		: document.seria + document.number,
			family_name: documentData.last_name,
			first_name: documentData.first_name,
			last_name: documentData.patronymic,
			document_series:
				typeRegistration === 'birth_certificate'
					? documentData.cert_series
					: document.seria,
			document_number:
				typeRegistration === 'birth_certificate'
					? documentData.cert_number
					: document.number,
			address: data.address,
			district: data.district,
			age: age,
			phone: data.phone,
			province: data.province,
			region: data.region,
			gender: documentData.gender === 'male' ? 'M' : 'F',

			team_members: data.team_members,
			telegram_id: 'sayt',
			pinfl: document.pinfl,
			birthdate:
				typeRegistration === 'passport'
					? document.birthdate
					: formattedDate,

			disability_group: paralimpycType ? paralimpycType : null,
			document_type:
				typeRegistration === 'passport'
					? document.seria === 'AD'
						? 5
						: 2
					: 1,
		};
		if (document.main_photo) {
			data2.image = document.main_photo;
		}
		setError2(false);

		axios
			.post(BASE_URL + 'create-participant/', data2)
			.then((res) => {
				setIsSuccess(true);
				setMessage("Siz muvaffaqqiyatli ro'yxatdan o'tdingiz");
			})

			.catch((err) => {
				if (err.response.status === 400) {
					setMessage(
						"Siz bu yo'nalish doirasida ro'yxatdan o'tgansiz."
					);
				} else {
					setMessage(
						"Xatolik yuz berdi iltimos qaytadan urinib ko'ring."
					);
				}

				setError2(true);
			})
			.finally(() => {
				setLoadingType(false);
			});
	};

	const closeModal2 = (e) => {
		setShowModal(false);
		if (isSuccess) {
			navigate('/');
		}
	};

	return (
		<Layout>
			<div className='home-page'>
				<BaseModal
					setLoadingModal={setLoadingModal}
					loadingModal={loadingModal}
					typeRegistration={typeRegistration}
					setTypeRegistration={setTypeRegistration}
					message={errorMessage}
					isModal={isModal}
					setDocument={setDocument}
					document={document}
					sendDocumentHandler={sendDocumentHandler}
					closeModal={closeModal2}
					error={error}
					setError={setError}
					setErrorMessage={setErrorMessage}
				/>

				<Modal
					last={true}
					error={error2}
					isModal={showModal}
					closeModal={closeModal2}
					message={message}
					loadingType={loadingType}
				/>

				<div className='right-side'>
					<div className='intro'>
						<div className='intro-right'>
							<h1>Ro‘yxatdan o‘tish</h1>
							<p>quyidagi ro’yxatni to’ldiring</p>
						</div>
						<div>
							<div className='logo'>
								<Link to='/'>
									<img
										src={require('../assets/logo.png')}
										alt='logo'
									/>
								</Link>
							</div>
						</div>
					</div>
					{!isModal && (
						<form
							onSubmit={(e) => {
								submitHandler(e);
							}}
							className='form'>
							<div className='form-content'>
								<div className='form-item'>
									<label htmlFor='name'>
										Ism sharifingiz
									</label>
									<input
										disabled
										type='text'
										id='name'
										value={
											documentData.first_name +
											' ' +
											documentData.last_name +
											' ' +
											documentData.patronymic
										}
										onChange={(e) =>
											setData({
												...data,
												name: e.target.value,
											})
										}
									/>
								</div>
								<div className='form-item'>
									<label htmlFor=''>
										Doimiy ro'yxatdan o'tgan hududingizni
										tanlang
										<span
											title="Tanlov nizomiga muvofiq doimiy yashash manzili ko'rsatilishi lozim."
											style={{
												cursor: 'pointer',
											}}
											className='ml-3'>
											<img
												src={infoIcon}
												alt='info icon'
												width={15}
												height={15}
											/>
										</span>
									</label>
									<select
										onChange={(e) =>
											handleProvinceSelectChange(e)
										}
										name=''
										value={data.province}
										id='city'>
										<option value='' selected disabled>
											Viloyat
										</option>
										{regions.map((item, index) => (
											<option key={index} value={item.id}>
												{item.name_uz}
											</option>
										))}
									</select>
								</div>
								<div className='form-item'>
									<label htmlFor=''>Tuman/Shahar</label>
									<select
										onChange={(e) =>
											handleRegionSelectChange(e)
										}
										name=''
										value={data.region}
										id='rayon'>
										<option value='' selected disabled>
											{loadingType
												? 'Iltimos biroz kuting...'
												: ''}
										</option>
										{districtsList.map((item, index) => (
											<option key={index} value={item.id}>
												{item.name}
											</option>
										))}
									</select>
								</div>
								<div className='form-item'>
									<label htmlFor=''>Mahalla</label>
									<select
										name=''
										id='rayon'
										value={data.district}
										onChange={(e) =>
											setData({
												...data,
												district: e.target.value,
											})
										}>
										<option value='' selected disabled>
											{loadingType
												? 'Iltimos biroz kuting...'
												: ''}
										</option>
										{quartersList.map((item, index) => (
											<option key={index} value={item.id}>
												{item.name}
											</option>
										))}
									</select>
								</div>
								<div className='form-item'>
									<label htmlFor=''>
										Manzil(ko’cha nomi va uy raqami)
									</label>
									<input
										value={data.address}
										// required
										onChange={(e) =>
											setData({
												...data,
												address: e.target.value,
											})
										}
										type='text'
										placeholder='Amir Temur 14-A uy'
									/>
								</div>
								<div className='form-item'>
									<label htmlFor=''>Telefon raqam</label>
									<input
										value={data.phone}
										// required
										onChange={(e) => {
											setData((prevData) => ({
												...prevData,
												phone: e.target.value,
											}));
										}}
										type='tel'
										placeholder='+998 XX  XXX XX XX'
									/>
								</div>
							</div>
							<div className='form-content'>
								<div className='form-item'>
									<label htmlFor='name'>Jinsingiz</label>
									<input
										disabled
										type='text'
										value={
											documentData.gender == 'male'
												? 'Erkak'
												: 'Ayol'
										}
									/>
								</div>
								<div className='form-item'>
									<label htmlFor='name'>
										Olimpiada yo‘nalishi
									</label>
									<select
										name=''
										ref={organizationSelect}
										defaultValue={''}
										onChange={(e) =>
											organizationHandler(e)
										}>
										{/* <option disabled value=''>
											Qaysi yo'nalishda qatnashmoqchisiz ?
										</option> */}

										{!loading
											? organizations.map(
													(item, index) => (
														<option
															key={index}
															value={item.id}>
															{item.name}
														</option>
													)
											  )
											: ''}
									</select>
								</div>
								<div className='form-item'>
									<label htmlFor='name'>
										Yo'nalish tanlang
									</label>
									<select
										name=''
										defaultValue={''}
										onChange={(e) =>
											mainCategoryHandler(e)
										}>
										<option disabled selected value=''>
											Qaysi yo'nalishda qatnashmoqchisiz ?
										</option>

										{categoriesList.length > 0
											? categoriesList.map(
													(item, index) => (
														<option
															key={index}
															value={JSON.stringify(
																item
															)}>
															{item.name}
														</option>
													)
											  )
											: ''}
									</select>
								</div>
								<div
									className={`${
										innerCategoriesList.length > 0
											? ''
											: 'disabled-form'
									} form-item`}>
									<label htmlFor='name'>
										Ichki yo'nalish tanlang
									</label>
									<select
										defaultChecked
										onChange={(e) =>
											innerCategoryHandler(e)
										}>
										<option disabled selected value=''>
											Ichki yo'nalish
										</option>

										{innerCategoriesList.length > 0
											? innerCategoriesList.map(
													(item, index) => (
														<option
															key={index}
															value={JSON.stringify(
																item
															)}>
															{item.name}
														</option>
													)
											  )
											: ''}
									</select>
								</div>
								<div
									className={`${
										smallCategoryList.length > 0
											? ''
											: 'disabled-form'
									} form-item`}>
									<label htmlFor='name'>
										Yo'nalish tanlang
									</label>
									<select
										disabled={
											smallCategoryList.length === 0
										}
										defaultChecked
										onChange={(e) =>
											smallCategoryHandler(e)
										}
										value={
											smallCategoryList.length > 0
												? JSON.stringify(
														innerCategoriesList[0]
												  )
												: ''
										}>
										<option disabled selected value=''>
											Yo'nalish
										</option>

										{smallCategoryList.map(
											(item, index) => (
												<option
													key={index}
													value={JSON.stringify(
														item
													)}>
													{item.name}
												</option>
											)
										)}
									</select>
								</div>
								{paralimpycType ? (
									<div className='form-item'>
										<label htmlFor='name'>
											Nogironlik turini tanlang
										</label>
										<select
											onChange={(e) =>
												setParalimpycType(
													e.target.value
												)
											}
											value={paralimpycType}>
											<option selected value='2'>
												Turni tanlang
											</option>
											<option value='1-guruh'>
												1-guruh
											</option>
											<option value='2-guruh'>
												2-guruh
											</option>
											<option value='3-guruh'>
												3-guruh
											</option>
											<option value='NBB'>NBB</option>
										</select>
									</div>
								) : (
									''
								)}
								{/* <div
									className={`${
										isTeam && isSolo ? '' : 'disabled-form'
									} form-item`}>
									<label htmlFor=''>
										Qaysi tartibda qatnashmoqchisiz
									</label>
									<select
										disabled={
											isTeam && isSolo ? false : true
										}
										className='checkbox-group'
										name=''
										value={data.team}
										onChange={(e) => teamHandler(e)}>
										{isTeam && (
											<>
												<option
													disabled
													value=''
													selected>
													Jamoa yoki yakka
												</option>
												<option value='jamoaviy'>
													jamoaviy
												</option>
												<option value='yakka'>
													yakka
												</option>
											</>
										)}
									</select>
								</div> */}

								{/* <div
									className={`${
										ages.length !== 0 ? '' : 'disabled-form'
									} form-item`}>
									<label htmlFor='name'>
										Tanlov yosh toifalari.
									</label>
									<select
										placeholder='Yosh toifalari'
										name=''
										onChange={(e) => {
											setAge(+e.target.value);
										}}>
										<option disabled value=''>
											Yosh toifalari
										</option>
										{ages.map((item, index) => (
											<option
												disabled
												key={index}
												value={item.age}>
												{item.age}
											</option>
										))}
									</select>
								</div> */}

								{/* <div
									className={`form-item box ${
										isTeam ? '' : 'disabled-form'
									}`}>
									<label htmlFor=''>
										Jamoa a’zolari ism va familiyalari
									</label>

									<textarea
										disabled={isTeam ? false : true}
										required={isTeam}
										value={data.team_members}
										onChange={(e) => teamMembersHandler(e)}
										placeholder={`${
											isTeam
												? '1. Abdullayev Jasur       2. Karimov Ali'
												: ''
										}`}
										name=''
										cols='20'
										rows='2'></textarea>
								</div> */}
								<div className='form-item mb-1 mt-3'>
									<label
										htmlFor='statute'
										className='d-flex align-items-center gap-2'>
										<input
											onChange={() =>
												setChecked(!checked)
											}
											type='checkbox'
											name='statute'
											id='statute'
										/>
										<p
											style={{
												fontSize: '10px',
												margin: 0,
											}}>
											<a
												className='text-primary'
												target={'_blank'}
												rel='noreferrer'
												href={
													file
														? 'https://adm.beshtashabbus.uz' +
														  file
														: ''
												}>
												Yo'nalish nizomi bilan tanishib
												chiqdim va nizom shartlariga
												roziman
											</a>
										</p>
									</label>
								</div>
							</div>
							<button id='button' type='submit'>
								Ro‘yxatdan o'tish
							</button>
						</form>
					)}
				</div>
				<a
					href='http://www.uz/uz/res/visitor/index?id=46379'
					className='calculation'
					target='_top'>
					<img
						height='31'
						src='http://cnt0.www.uz/counter/collect?id=46379&pg=http%3A//uzinfocom.uz&&col=0063AF&amp;t=ffffff&amp;p=DD7900'
						width='88'
						border='0'
						alt='Топ рейтинг'
					/>
				</a>
			</div>
		</Layout>
	);
};

export default Registration;
