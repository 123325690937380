const generateOld = (date) => {
	const birthDate = new Date(date);
	const today = new Date();
	const old =
		today.getFullYear() -
		birthDate.getFullYear() -
		(today.getMonth() < birthDate.getMonth() ||
		(today.getMonth() === birthDate.getMonth() &&
			today.getDate() < birthDate.getDate())
			? 1
			: 0);

	return old
};
export default generateOld;
