import React from 'react';
import succesBtn from '../assets/succesButton.png';
import modalBtn from '../assets/modalbtn.png';

import './Modal.css';
import { Link } from 'react-router-dom';
const Modal = ({
	isModal,
	closeModal,
	children,
	error,
	// isMain,
	message,
	isCheck,
	last,
	loadingType,
}) => {
	return (
		<div
			id='myModal'
			className='modal'
			style={isModal ? { display: 'block' } : { display: 'none' }}>
			<div className='modal-content'>
				<div
					className='modal-header'
					style={{
						backgroundColor: error ? '#e22a41' : '#2ab67b',
						minHeight: '30px',
					}}>
					{/* {isMain ? (
						<></>
					) : ( */}
					<span className='close' onClick={closeModal}>
						&times;
					</span>
					{/* )} */}
				</div>
				<div className='modal-body'>
					{error && !isCheck ? (
						<>
							{message ? (
								<p className='text-danger'>{message}</p>
							) : (
								<>
									<h4>Ma'lumotlar noto'g'ri kiritildi</h4>
									<p>iltimos qaytadan urinib ko'ring</p>
								</>
							)}
						</>
					) : (
						''
					)}
					{error && isCheck ? (
						<div>
							<p className='text-danger'>
								Siz hali ro'yxatdan o'tmagansiz{' '}
							</p>
							<Link
								className='btn btn-primary text-decoration-none text-center d-block'
								to={'/registration'}>
								Ro'yxatdan o'tish 👉
							</Link>
						</div>
					) : (
						''
					)}
					{!error && message ? (
						<>
							<p>{message}</p>
							<p>
								Yangiliklarni kuzatib borish uchun quyidagi
								kanallarga a'zo bo'ling:
							</p>
							<div className='d-flex flex-column flex-sm-row justify-content-between gap-3	'>
								<a
									className='t-link'
									href='https:t.me/alisher_sadullaev'>
									Alisher Sadullayev
								</a>
								<a
									className='t-link'
									href='https://t.me/Minsportuz'>
									Yoshlar siyosati va sport vazirligi
								</a>
								<a
									className='t-link'
									href='https:t.me/yoshlaragentligi'>
									Yoshlar ishlari agentligi
								</a>
								<a
									className='t-link'
									href='https:t.me/beshtashabbus_uz'>
									Besh tashabbus
								</a>
							</div>
						</>
					) : (
						''
					)}

					{children}
				</div>
			</div>
		</div>
	);
};

export default Modal;
