import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.use(HttpApi)
	.init({
		supportedLngs: ['ru', 'uz'],
		fallbackLng: 'uz',
		detection: {
			order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
			caches: ['cookie'],
		},
		backend: {
			loadPath: '/assets/locales/{{lng}}/translation.json',
		},
		react: {
			useSuspense: false,
		},
	});
ReactDOM.render(
	// <React.StrictMode>
	<App />,
	// </React.StrictMode>,
	document.getElementById('root')
);
